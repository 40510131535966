import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { AuthService } from '../services';
import { PermissionsEnum } from '../models/PermissionsEnum';

@Injectable({
    providedIn: 'root',
})
export class GuardCenter implements CanActivate {
    private url = '/cabinet/service-center';
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkPermission(url);
    }
    checkPermission(url: string): boolean {
        if (url == this.url) {
            const permissions = this.authService.getPermissionCurrentProfile();
            const result = permissions.includes(PermissionsEnum.AccessCabinetServiceCenter);
            return result;
        } else {
            const permissions = this.authService.getPermissionCurrentProfile();
            const result = permissions.includes(PermissionsEnum.AccessCabinetSupportCenter);
            return result;
        }
    }
}
